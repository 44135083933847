import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { HTTP_CODE_UNAUTHORIZED } from "enums/httpCodes";
import { ServerErrorActionType } from "src/enums";
import { UriComponent, VoidCallback } from "src/types/common";
import isLockedByCaptcha from "src/utils/isLockedByCaptcha";
import ApiError from "./apiError";
import { addRequestToHistory } from "./requestHistory";

export type Params = Record<string, UriComponent>;

export const isApiError = (error: unknown): error is ApiError =>
  error instanceof ApiError;

const defaultInit: RequestInit = {
  credentials: "include",
  mode: "cors",
};

const additionalUnauthorizedErrorHandlers: VoidCallback[] = [];
export const addAdditionalUnauthorizedErrorHandler = (cb: VoidCallback) => {
  additionalUnauthorizedErrorHandlers.push(cb);
};

type captchaHandlers = (error: ApiError) => void;
const captchaErrorHandlers: captchaHandlers[] = [];
export const addCaptchaHandler = (cb: (error: ApiError) => void) => {
  captchaErrorHandlers.push(cb);
};

const sessionTokenErrorHandlers: VoidCallback[] = [];
export const addSessionTokenRefreshHandler = (cb: VoidCallback) => {
  sessionTokenErrorHandlers.push(cb);
};

interface ServerErrorAction {
  type: ServerErrorActionType;
}

const serverErrorInterceptor = (resp: Response, action?: ServerErrorAction) => {
  addRequestToHistory(resp.url, resp.status);

  if (!resp.ok) {
    const error = new ApiError({
      status: resp.status,
      statusText: resp.statusText,
    });

    if (resp.status === HTTP_CODE_UNAUTHORIZED) {
      if (action?.type === ServerErrorActionType.LOGOUT) {
        additionalUnauthorizedErrorHandlers.forEach((cb) => cb());
      } else {
        sessionTokenErrorHandlers.forEach((cb) => cb());
      }
    }

    isLockedByCaptcha(error) && captchaErrorHandlers.forEach((cb) => cb(error));

    return resp
      .json()
      .catch(() => {
        throw error;
      })
      .then((json) => {
        throw new ApiError({
          status: resp.status,
          statusText: resp.statusText,
          body: json,
        });
      });
  }

  return resp;
};

const networkErrorInterceptor = () => {
  throw new ApiError({ status: 0, statusText: "network error" });
};

export const enhancedFetch = (
  url: RequestInfo,
  init: RequestInit = {},
  action?: ServerErrorAction
) => {
  const options = { ...defaultInit, ...init };

  if (!options.headers) {
    options.headers = {};
  }

  Object.assign(options.headers, AnalyticsUtils.getHeaders());

  return fetch(url, options)
    .catch(networkErrorInterceptor)
    .then((response) => serverErrorInterceptor(response, action));
};

export default enhancedFetch;

export const formatParams = (params: Params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");

export const urlAndParams = (url: string, params: Params) =>
  `${url}?${formatParams(params)}`;
