export enum ExperimentIds {}

export enum SCREEN_STATES {
  FULL_VIEW = "full_view",
  LOADING = "loading",
  MINI_VIEW = "mini_view",
  OFFER = "offer",
}

export enum EventNames {
  AGENT_GUEST_REGISTRATION = "AgentGuestRegistration",
  AGENT_SENT_LINK = "AgentSentLink",
  AUCTION_ENTERED = "auction_entered",
  BANNER_RECEIVED = "banner_received",
  BANNER_SHOWN = "banner_shown",
  CAPTCHA_EVENT = "show_captcha",
  CASHIER_DISMISSED = "cashier_dismissed",
  CASHIER_DISPLAYED = "cashier_displayed",
  CHANGE_SETTING = "change_setting",
  CLOSE_PURCHASE_SCREEN = "close_purchase_screen",
  CLOSE_ROULETTE = "close_roulette",
  CODE_ENTERED = "code_entered",
  DARK_THEME = "DarkTheme",
  FOLLOW_UNFOLLOW = "FollowUnfollow",
  GIFT_INSUFFICIENT_BALANCE = "GiftInsufficientBalance",
  GIFT_PURCHASE_FAILED = "GiftPurchaseFailed",
  GIFT_PURCHASE_SUCCESSFUL = "GiftPurchaseSuccessful",
  GUEST_REGISTRATION = "GuestRegistration",
  IN_APP_PURCHASE = "InAppPurchase",
  LEADERBOARD_LOADED = "leaderboard_loaded",
  LINK_OPENED = "LinkOpened",
  LINK_SHARED = "LinkShared",
  LIVE_FAMILY_ENTER = "LiveFamilyEnter",
  LIVE_PLAY = "LivePlay2",
  LIVE_PLAY_BUFFERING_ENDED = "LivePlayBufferingEnded",
  LIVE_SESSION = "LiveSession",
  LOGIN_COMPLETED = "login_completed",
  LOGIN_FAILED = "login_failed",
  LOGOUT = "logout",
  MAKE_BET = "make_bet",
  MULTI_STREAM_INVITATION_ANSWER = "multi_stream_invitation_answer",
  MULTI_STREAM_INVITATION_DISPLAYED = "multi_stream_invitation_displayed",
  MULTI_STREAM_INVITATION_RECEIVED = "multi_stream_invitation_received",
  OFFER_DISPLAYED = "offer_displayed",
  ONBOARDING_END = "onboarding_end",
  ONBOARDING_START = "onboarding_start",
  ONBOARDING_SWITCH = "onboarding_switch",
  ONE_CLICK_END = "one_click_end",
  ONE_CLICK_START = "one_click_start",
  OPEN_USER_INFO = "OpenUserInfo",
  PAGE_CLOSE = "page_close",
  PAGE_LOADING = "page_loading",
  PAGE_VIEW = "page_view",
  PAYMENT_ERROR = "payment_error",
  PAYMENT_METHOD_ERROR_APPEAR = "payment_method_error_appear",
  PAYMENT_METHOD_LOADED_TIME = "payment_method_loaded_time",
  PAYMENT_METHOD_LOADING_ERROR = "payment_method_loading_error",
  PAYMENT_METHOD_NOT_LOADED_TIME = "payment_method_not_loaded_time",
  PERSONAL_OFFERS_PAGE_DISPLAYED = "personal_offers_page_displayed",
  PERSONAL_OFFERS_RECEIVE_ERROR = "personal_offers_receive_error",
  PERSONAL_OFFERS_RECEIVED = "personal_offers_received",
  PERSONAL_OFFERS_SKIPPED = "personal_offers_skipped",
  PREMIUM_MESSAGE_DISPLAY = "premium_message_display",
  PURCHASE_FAILED = "purchase_failed",
  PURCHASE_FTP = "ftp_purchase",
  PURCHASE_INIT = "purchase_initiated",
  PURCHASE_SUCCESS = "purchase",
  REGISTRATION_COMPLETED = "registration_completed",
  ROULETTE_DISAPPEARED = "roulette_disappeared",
  ROULETTE_SHOWN = "roulette_shown",
  SAS_SERVER_RESPONSE = "server_response",
  SCREEN_VIEW = "tango_screen_view",
  SEARCH_INVOKED = "search_invoked",
  SEARCH_RESULT = "search_result",
  SEND_GIFT = "SendGift",
  SHARE_EVENT = "share",
  SINGLE_CHAT_MESSAGE_SENT = "single_chat_message_sent",
  SKELETON_LOADED_TIME = "skeleton_loaded_time",
  SKELETON_NOT_LOADED_TIME = "skeleton_not_loaded_time",
  SOCIAL_GAMES_TAB_DISPLAYED = "tab_displayed_social_games",
  SPECIAL_OFFER_DISPLAYED = "special_offer_displayed",
  TANGO_LOGIN = "tango-login",
  TANGO_PURCHASE = "tango-purchase",
  TCNN_CANCELLED = "TcnnCancelled",
  TCNN_CLICKED = "TcnnClicked",
  TCNN_DISPLAYED = "TcnnDisplayed",
  TCNN_RECEIVED = "TcnnReceived",
  UI_ACTION = "ui_action",
  UI_SHOWN = "ui_component_shown",
  USE_SETTING = "use_setting",
  WIN_SHOWN = "win_shown",
  WOF_CONFIGURATION_CLIENT = "wof_configuration_client",
}

export enum EventReasons {
  ERROR = "error",
  SELF_LOGOUT = "self_logout",
}

export enum EventCategory {
  LOGIN = "Login",
  PURCHASE = "Purchase",
}

export enum EventFields {
  // tcnn specific
  A_TCNN_TRACKING_NUMBER = "tcnn_tracking_id",
  ACTION = "action",
  AMOUNT_USD = "amount_usd",
  ANALYTICS_KEY = "analyticsKey",
  ANCHOR_ID = "anchor_id",
  ANCHOR_TYPE = "anchor_type",
  BALANCE = "balance",
  // banners
  BANNER_TRACKING_ID = "banner_tracking_id",
  CAMPAIGN_ID = "campaign_id",
  CHAT_ID = "chat_id",
  COMMENT = "comment",
  // happy-moments specific
  CREDIT_DEDUCTION = "credit_deduction",
  CREDITS = "credits",
  // web purchase
  CURRENCY = "currency",
  CURRENT_BALANCE = "current_balance",
  DARK_MODE_ENABLED = "darkModeEnabled",
  DEVICE_CATEGORY = "device_category",
  DEVICE_SOURCE = "device_source",
  DURATION = "duration",
  DURATION_QUALITY_HD = "hd_duration",
  DURATION_QUALITY_LD = "ld_duration",
  DURATION_QUALITY_SD = "sd_duration",
  ENGAGEMENT_DURATION = "tango_engagement_time_msec",
  ERROR_TEXT = "error_text",
  FEED_POST_AUTHOR_ID = "author_id",
  FEED_POST_ID = "post_id",
  FLAGS = "flags",
  FOREGROUND_ID = "foreground_id",
  FROM_MINI_PROFILE = "is_from_mini_profile",
  GIFT_ID = "gift_id",
  GIFT_SOURCE = "GiftSource",
  GUEST_REGISTRATION_SOURCE = "GuestRegistrationSource",
  HAPPY_MOMENT_ID = "happy_moment_id",
  HASHED_EMAIL = "hashedEmail",
  HASHED_PHONE_NUMBER = "hashedPhoneNumber",
  IN_APP_PURCHASE_SOURCE = "InAppPurchaseSource",
  // make_bet
  INCREASE_AMOUNT = "increase_amount",
  INT_VALUE = "int_value",
  INTERACTION_ID = "interaction_id",
  INVITATION_ID = "invitation_id",
  IS_FIRST = "isFirst",
  IS_FOLLOW = "is_follow",
  IS_STANDALONE = "is_standalone",
  ITEM_ID = "item_id",
  ITEM_NAME = "item_name",
  ITEM_TYPE = "item_type",
  ITEMS = "items",
  // Josh integration only
  JOSH_ID = "josh_id",
  LANGUAGE = "language",
  LAST_TANGO_PURCHASE_VALUE = "last_tango_purchase_value",
  LIVE_SESSION_ID = "LiveSessionId",
  LIVE_SESSION_STREAM_FILTER_BACKGROUND = "LiveSessionStreamFilterBackground",
  // LiveSession specific
  LIVE_SESSION_STREAM_FILTER_BEAUTY = "LiveSessionStreamFilterBeauty",
  LIVE_SESSION_STREAM_FILTER_BEAUTY_MASK = "LiveSessionStreamFilterBeautyMask",
  LOCAL_CURRENCY_AMOUNT = "local_currency_amount",
  LOGGED_IN_ACCOUNT_ID = "account_id",
  LOGIN_LINK_TYPE = "link_type",
  LOGIN_PROVIDER = "reg_type",
  // OpenUserInfo (mini-profile)
  MINI_PROFILE_HAPPY_MOMENT_ID = "encrypted_happy_moment_id",
  MINI_PROFILE_IS_PUBLISHER = "is_publisher",
  NEW_VALUE = "new_value",
  NFT_CARD_ID = "nft_card_id",
  OFFER_ID = "offer_id",
  OFFER_NAME = "offer_name",
  // personal offer specific
  OFFER_TYPE = "offer_type",
  OFFER_VERSION = "offer_version",
  OLD_VALUE = "old_value",
  ONBOARDING_VIDEO_TITLE = "onboarding_video_title",
  ONE_CLICK_COINS_DIFFERENCE = "coins_diff",
  ONE_CLICK_GIFTING = "one_click",
  ONE_CLICK_PURCHASE = "one_click_purchase",
  ONE_CLICK_TYPE = "one_click_type",
  PAYMENT_GATEWAY = "payment_gateway",
  PAYMENT_METHOD = "payment_method",
  PAYMENT_PROVIDER = "payment_provider",
  PAYMENT_TYPE = "payment_type",
  PEER_ID = "peer_id",
  PERSISTENCE_MODE = "persistence_mode",
  PERSONAL_OFFER_TYPE = "personal_offer_type",
  PLACE = "place",
  PLATFORM = "platform",
  PLATFORM_TYPE = "platform_type",
  POSITION = "position",
  PREMIUM_MESSAGE_ID = "premium_message_id",
  PREVIOUS_SCENARIO_ID = "previous_scenario_id",
  PREVIOUS_SCREEN_NAME = "tango_previous_screen",
  PREVIOUS_SCREEN_STATE = "previous_screen_state",
  PREVIOUS_STREAM_ID = "previous_stream_id",
  PREVIOUS_UI_COMPONENT = "previous_ui_component",
  PRODUCT_IDENTIFIER_TANGO = "product_identifier_tango",
  PROFILE_ID = "profile_id",
  PURCHASE_SOURCE = "purchase_source",
  REASON = "reason",
  RECIPIENT_ACCOUNT_ID = "recipient_account_id",
  RELATION_ID = "relation_id",
  RESULT = "result",
  RESULT_CODE = "result_code",
  RULE_ID = "rule_id",
  // onboarding specific
  SCENARIO_ID = "scenario_id",
  SCREEN_NAME = "screen_name",

  SCREEN_STATE = "screen_state",
  SENDER_ID = "sender_id",
  SENDER_ID_STREAM_ID = "sender_id_stream_id",

  SESSION_ID = "session_id",
  SETTING_ID = "setting_id",
  SHARE_SOURCE_ID = "source_id",
  // share specific
  SHARE_TYPE = "share_type",
  SIZE = "size",
  SOURCE = "source",
  STATUS = "status",
  STREAM_CHAT_SOURCE_TYPE = "chat_type",
  STREAM_ID = "stream_id",
  STREAM_KIND = "streamKind",
  STREAM_MODE = "mode",
  STREAM_RANK_IN_LIST = "rank_in_list",
  // LivePlay-specific
  STREAM_SOURCE = "LiveSessionStreamSource",

  STREAM_TAG = "tag",
  STREAM_TYPE = "stream_type",
  // navigation / engagement
  TANGO_SCREEN = "tango_screen",
  // shared
  TARGET = "target",
  // search
  TEXT = "text",
  THRESHOLD = "threshold",
  TICKET_PRICE = "ticket_price",
  TIME_SPEND = "time_spend",
  // Required
  TIMESTAMP = "timestamp",
  TOTAL_AMOUNT = "total_amount",
  TRANSACTION_ID = "transaction_id",

  TWC_VERSION = "tango_version",
  UI_COMPONENT = "ui_component",

  // FollowUnfollow
  USER_ID = "user_id",
  VALUE = "value",
  VIEW_SESSION_ID = "view_session_id",
  WITH_EXCLUSIVE_POSTS = "with_exclusive_posts",
}

export enum BroadcastType {
  OBS = "OBS",
}
export enum MultiBroadcastInviteAnchorType {
  BG = "BG",
  DEFAULT = "DEFAULT",
  STREAM = "STREAM",
}

export enum MultiBroadcastAnswerResult {
  ACCEPTED = 1,
  DECLINED = 2,
  EXPIRED = 4,
}

export enum PlatformsTypes {
  WEB = "WEB",
  WEB_VIEW = "WEBVIEW",
}

export enum STREAM_SOURCE {
  CHAT = 0,
  DIRECT_LINK = 16,
  FOLLOWING_FEED = 8,
  HAPPY_MOMENT = 17,
  LEADERBOARD_ALLTIME = 12,
  LEADERBOARD_DAILY = 10,
  LEADERBOARD_WEEKLY = 11,
  NEARBY_FEED = 19,
  NEW_FEED = 7,
  POPULAR_FEED = 6,
  PROFILE_LIVE_BUTTON = 14,
  RECOMMENDATIONS_FEED = 21,
  SYSTEM_NOTIFICATION = 2,
  TAGGED_FEED = 20,
}

export enum LoginSource {
  CENTRAL_BUTTON = 2,
  HEADER_BUTTON = 1,
  PROFILE_BUTTON = 3,
}

export enum PURCHASE_SOURCE {
  BECOME_VIP = 2,
  BUY_COINS_PAGE = 11,
  CASHIER = 18,
  CHAT_1_TO_1 = 9,
  CHAT_LIVE_FAMILY = 10,
  COUNT_BANNER = 23,
  FTP_BANNER = 22,
  HAPPY_MOMENTS = 13,
  MOMENTS_BUY_COINS = 12,
  OFFLINE_GIFTING = 5,
  OFFLINE_GIFTING_FEED = 8,
  OFFLINE_GIFTING_POST = 6,
  OFFLINE_GIFTING_PROFILE = 7,
  PAID_ENTRY = 4,
  REDEEM_GIFT_CARD = 17,
  REFILL = 3,
  STREAM = 1,
  SUBSCRIPTION_CONFIRM = 24,
  SUBSCRIPTION_PROFILE = 14,
  SUBSCRIPTION_RENEW = 25,
  SUBSCRIPTION_STREAM = 15,
  WEB_LANDING = 21,
  WEBVIEW_APM_OFFERS = 26,
  WEBVIEW_SAFECHARGE = 16,
  WEBVIEW_SINGLE_PAYMENT_METHOD = 27,
}

export enum UI_COMPONENT {
  BECOME_VIP_BANNER = "become_vip_banner",
  CASHIER = "cashier",
  DOWN_CASHIER = "down_cashier",
  GIFT_DRAWER = "gift_drawer_refill",
  LAND_CASHIER = "land_cashier",
  LANDING_PAGE = "landing_page",
  PERCENT_BANNER = "20_percent_banner",
  REFILL = "refill",
  SEND_GIFT = "send_gift_refill",
  UP_CASHIER = "up_cashier",
  WELCOME_BANNER = "welcome_bonus_banner",
}

export enum BI_LANDING_FIELD {
  AMOUNT = "amount",
  ANCHOR_TYPE = "anchor_type",
  COMMENT = "comment",
  CREDIT = "credit",
  CURRENCY_CODE = "currency_code",
  CURRENCY_SWITCHER_ON = "is_switcher_on",
  ERROR_CODE = "error_code",
  ERROR_MESSAGE = "error_message",
  HAS_3DS = "has_3ds",
  IS_GUEST_MODE = "is_guest_mode",
  LOGIN_COMPLETED = "login_completed",
  LOGIN_FAILED = "login_failed",
  MARKET_OFFER_ID = "market_offer_id",
  MESSAGE_UUID = "message_uuid",
  OFFERS_NUM = "offers_num",
  PAGE_CLOSE = "page_close",
  PAGE_LOADING = "page_loading",
  PAGE_NOT_DISPLAYED = "page_not_displayed",
  PAGE_TITLE = "page_title",
  PAGE_TYPE = "page_type",
  PAGE_VIEW = "tango_page_view",
  PAYMENT_METHOD = "payment_method",
  PLACE = "place",
  POSITION = "position",
  PREVIOUS_CURRENCY_CODE = "previous_currency_code",
  PRICE = "price",
  PRICE_POINT_ID = "price_point_id",
  REASON = "reason",
  SECURE_CHECK = "secure_check",
  SERVER_OFFER_ID = "server_offer_id",
  SOURCE = "source",
  TAG = "tag",
  TAXES = "taxes",
  TEXT_ERROR = "tango_text_error",
  TIME_LOADING = "time_loading",
  TRACKING_ID = "tango_tracking_id",
  TRIGGER_ID = "tango_trigger_id",
}

export enum SUBSCRIBE_TARGET {
  BLURRED_PHOTOS = "subscribe_popup_blurred_panel",
  BROWSE_LIFE_TARGET = "browse_life",
  CANCEL_POPUP_CLOSE_TARGET = "cancel_popup_cross",
  MINI_PROFILE = "subscribe_popup_mini_profile",
  ONE_CLICK_PURCHASE = "one_click_purchase",
  POPUP_TARGET = "subscribe_popup",
  PROFILE_TARGET = "subscribe_profile",
  STREAM_TARGET = "subscribe_stream",
  SUBSCRIBERS_TARGET = "subscribers",
  SUBSCRIPTIONS_TARGET = "subscriptions",
}

export enum UNSUBSCRIBE_TARGET {
  CANCEL_POPUP_TARGET = "cancel_popup",
  POPUP_TARGET = "unsubscribe_from_bc",
  PROFILE_TARGET = "unsubscribe_profile",
}

export enum SIGNIN_TARGET {
  CODE_ENTERED = "code_entered",
  CONTINUE_WITH_PHONE = "continue_with_phone",
  SEND_CODE_AGAIN = "send_code_again",
  SIGNIN_WITH_APPLE = "signin with apple",
  SIGNIN_WITH_GOOGLE = "signin with google",
  SIGNIN_WITH_PHONE = "signin with phone",
}

export enum SIGNOUT_TARGET {
  SIGNOUT = "log_out",
  SIGNOUT_CANCEL = "logout_cancel",
  SIGNOUT_CONFIRM = "logout_confirm",
}

export enum SIGNOUT_COMMENT {
  HEADER = "header",
  SETTINGS = "settings",
}

export enum RegistrationSource {
  ANOTHER_PROFILE_CHAT = 16, // - Another profile chat button
  ANOTHER_PROFILE_LIKE_POST = 20, // - Another profile post like
  ANOTHER_PROFILE_SEND_GIFT = 19, // - Another profile send gift
  ARTISTS_CLUB = 96,
  CHAT_TAB = 14, // - Chat tab
  DIRECT_LINK = 69,
  FOLLOW_ANOTHER_PROFILE = 11, // - Follow from another profile
  FOLLOW_FROM_BROADCAST_ENDED = 35, // - GuestRegistrationSourceFollowFromBroadcastEnded
  FOLLOW_FROM_GIFTERS = 27, // - GuestRegistrationSourceFollowFromGiftersList
  FOLLOW_FROM_HAPPY_MOMENTS = 37, // - FollowStories
  FOLLOW_LEADERBOARD = 10, // - Follow from leaderboard
  FOLLOWING_TAB = 13, // - Following tab
  HOME_PAGE = 3, // - Login View on Home Page
  HOME_PAGE_V2 = 203, // Login View on Home Page V2
  HOME_PAGE_V3 = 303, // Login View on Home Page V3
  LOYALTY = 97,
  MANDATORY_LOGIN_PUBLIC_STREAM_VIEW = 66, // - mandatory registration to view public stream
  PRIVATE_STREAM_ENTRY = 33, // - GuestRegistrationSourcePrivateStreamPaidEntry
  PROFILE = 4, // - Your mini profile
  PROFILE_REPORT = 29,
  PROMOTION = 41, // - Clicked login button on promotion screen
  REGISTRATION_PAGE = 68, // Marketing registration page /registration
  SEARCH = 25, // - Search with open self profile
  SELF_PROFILE_ICON = 1, // - Self profile icon (left up corner)
  SEND_GIFT = 7, // - Send gift(s)
  SEND_GIFT_HAPPY_MOMENTS = 39, // - SendStoryGift
  SOCIAL_GAMES_GAME_CARD = 95,
  SOCIAL_GAMES_REFILL = 94,
  START_STREAM = 0, // - Start stream button
  SUBSCRIBERS_ONLY_PROFILE_TAB = 100, // Clicked subscribers only on profile screen
  TCNN_CTA = 30, // - GuestRegistrationSourceTCNNCTA
  UNKNOWN = -1,
  UNSUBSCRIBED_CASHIER_OFFER = 98, // - login/registration from cashier
}

export enum SCREEN_NAME {
  ARTIST_CLUB = "artist_club",
  BROADCAST = "broadcast",
  CHAT_TAB = "chat_tab",
  EMPTY_CHAT = "main.conversations",
  ENTER_SMS_CODE = "enter_sms_code",
  FEED = "main.news_feed",
  FOLLOWING_TAB = "following_tab",
  HAPPY_MOMENTS = "happy_moments",
  INSTALL_TANGO_APP = "install_tango_app",
  LEADERS = "leaderboard",
  LIFE_PLAYER_PRIVATE_BUY_TICKET = "live.player.private.buy_ticket",
  LIVE_TAB = "live_tab",
  LOYALTY = "loyalty",
  MAIN_HOME_PAGE = "main.home_page",
  MAIN_HOME_PAGE_V2 = "main.home_page_v2",
  MAIN_HOME_PAGE_V3 = "main.home_page_v3",
  MOBILE_APP_SETTING = "mobile_app_settings",
  MY_PROFILE = "profile.my_own",
  MY_PROFILE_SUBSCRIBERS_NAME = "profile.subscribers",
  MY_PROFILE_SUBSCRIPTIONS_NAME = "profile_subscriptions",
  OTHER_PROFILE = "profile.other",
  PAYMENT_ERROR = "payment_failed",
  PAYMENT_OFFERS = "offers_page",
  PAYMENT_SETTINGS = "settings.payment",
  PAYMENT_SUCCESS = "payment_success",
  PAYMENT_SUMMARY = "payment_information",
  PROFILE = "profile",
  REGISTRATION_FAIL_DIALOG = "registration_fail_dialog",
  REGISTRATION_SIGN_IN = "registration.sign_in",
  TIMELINE = "feed_tab",
  VIEW_BROADCAST = "view_broadcast_screen",
  VOUCHERS = "vouchers",
  WEB_SWITCH_STREAMS_TUTORIAL = "web_switch_streams_tutorial",
}

export enum PURCHASE_ORIGIN {
  LOYALTY_OFFERS = "LoyaltyOffers",
  MINTROUTE = "Mintroute",
  WEB_CASHIER = "WebCashier",
  WEB_NATIVE_LANDING_PAGE = "WebNativeLandingPage",
  WEB_ONE_CLICK = "WebOneClick",
  WEB_REFILL = "WebRefill",
}

export enum PERSONAL_OFFERS_SOURCE {
  CASHIER = "cashier",
  LANDING_PAGE = "landing_page",
  LOYALTY_OFFERS = "loyalty_offers",
  MINTROUTE = "mintroute",
  ONE_CLICK_CALLS = "ONE_CLICK_CALLS",
  ONE_CLICK_IN_STREAM = "ONE_CLICK_IN_STREAM",
  ONE_CLICK_MESSAGES = "ONE_CLICK_MESSAGES",
  ONE_CLICK_PREMIUM_IN_STREAM = "ONE_CLICK_PREMIUM_IN_STREAM",
  ONE_CLICK_PREMIUM_IN_STREAM_FT = "ONE_CLICK_PREMIUM_IN_STREAM_FT",
  ONE_CLICK_PREMIUM_IN_STREAM_REGULAR_PURCHASE = "ONE_CLICK_PREMIUM_IN_STREAM_RP",
  ONE_CLICK_PREMIUM_MESSAGES = "ONE_CLICK_PREMIUM_MESSAGES",
  ONE_CLICK_PROFILE = "ONE_CLICK_PROFILE",
  ONE_CLICK_SOCIAL_GAMES = "ONE_CLICK_SOCIAL_GAMES",
  REFILL = "refill",
  WEB_ONE_CLICK = "WebOneClick",
}

export enum PERSONAL_OFFER_TYPE {
  PERSONAL_OFFER = "PersonalOffer",
  SPECIAL_PERSONAL_OFFER = "SpecialPersonalOffer",
  WELCOME_OFFER_NEWCOMER = "WelcomeOfferNewcomer",
}

export enum GIFT_ORIGIN {
  COLLECTION = "collection",
  GIFT_DRAWER = "gift_drawer",
  MOS = "mos",
  STICKER = "sticker",
  TICKET = "ticket",
}

export enum LANDING_PLACE {
  LP_IN_CASHIER = "lp_in_cashier",
  LP_IN_REFILL = "lp_in_refill",
}

export enum UIActionTarget {
  BACK = "back",
  CURRENCY_PICKER = "currency_picker",
  LP_OFFER_CLICK = " lp_offer_click",
  OFFER_CLICKED = "offer_clicked",
  PAY = "pay",
  REDEEM_CODE = "redeem_code",
  SHOW_ALL_OFFERS = "show_all_offers",
  SKIP = "skip",
  WELCOME_OFFER_CLICKED = "welcome_offer_clicked",
}

export enum SearchParams {
  BING_CLICK_ID = "msclkid",
  FACEBOOK_CLICK_ID = "fbclid",
  GOOGLE_CLICK_ID = "gclid",
  IR_CLICK_ID = "irclickid",
  SNAPCHAT = "ScCid",
  TWITTER = "twclid",
  VOLUUM = "vbrandid",
}

export enum UtmParams {
  AD_GROUP_ID = "adgroupid",
  UTM_CAMPAIGN = "utm_campaign",
  UTM_CONTENT = "utm_content",
  UTM_ID = "utm_id",
  UTM_KEY = "comment",
  UTM_MEDIUM = "utm_medium",
  UTM_SOURCE = "utm_source",
  UTM_TERM = "utm_term",
}

export enum AdditionalDataPoints {
  LANDING_URL = "landing_url",
  REFERRER_URL = "referrer_url",
}

export enum MARKETING_PLATFORM {
  BING = "BING",
  FACEBOOK = "FACEBOOK",
  GENERAL = "GENERAL",
  GOOGLE = "GOOGLE",
  IMPACT = "IMPACT",
  SNAPCHAT = "SNAPCHAT",
  TWITTER = "TWITTER",
  VOLUUM = "VOLUUM",
}

export enum TranslateItemType {
  CACHE = "translate_from_cache",
  SERVER = "translate_from_server",
}

export enum SINGLE_CHAT_MESSAGE_SENT_FLAGS {
  GIFT = "gift",
}

export enum SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID {
  PREMIUM_MESSAGE = "premium_message",
}

export enum SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE {
  RECEIVED = "received",
  SENT = "sent",
}

export enum AcmeBIType {
  PURCHASE_TRANSACTION = "Acme_PurchaseTransaction",
}

export enum Comment {
  MAIN = "main",
  MAIN_BOTTOM = "main_bottom",
  STREAM = "stream",
  SUBMENU = "submenu",
}
